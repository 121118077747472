import React from 'react';
import Header from '../components/Header';

import print2 from "../assets/images/depoimentos/print2.webp";
import print3 from "../assets/images/depoimentos/print3.webp";
import print4 from "../assets/images/depoimentos/print4.webp";
import print5 from "../assets/images/depoimentos/print5.webp";
import print6 from "../assets/images/depoimentos/print6.webp";
import print8 from "../assets/images/depoimentos/print8.webp";
import print9 from "../assets/images/depoimentos/print9.webp";
import elegantDress10 from "../assets/images/elegant_dress_10.webp";
import elegantDress13 from "../assets/images/elegant_dress_13.webp";
import jessicaFotoBg from "../assets/images/perfil_2.webp";
import training1 from "../assets/images/training1.webp";
import training2 from "../assets/images/training2.webp";
import garantia7 from "../assets/images/garantia7.webp";

import { Accordion, AccordionItem, Button, useDisclosure } from "@nextui-org/react";
import ModalDownloadPlanner from '../components/ModalDownloadPlanner';
import ModalPurchaseLead from '../components/ModalPurchaseLead';
import VideoVSL from '../components/Video';
import MetaPixel from '../components/MetaPixel';
import { Helmet } from 'react-helmet';
import UTMify from '../components/UTMify';

function TresPV() {
  const modalDownloadProps = useDisclosure();
  const modalPurchaseProps = useDisclosure();

  return (
    <>
      <Helmet>
        <title> 3 Semanas para o Vestido - Jéssica Vilela Nutri</title>
        <meta name="author" content="Jessica Vilela - Nutricionista" />
        <meta name="description" content="Programa de emagrecimento saudável de curto prazo. Sem passar fome e sem dietas mirabolantes!
          Ideal para noivas, madrinhas, formandas e mulheres que desejam entrar em forma rapidamente e com saúde. Resultados reais e duradouros!" />
        <meta name="keywords" content="casamento, noiva, madrinha de casamento, dieta, vestido de noiva, formatura, Programa de emagrecimento saudável,
          Emagrecer em 3 semanas, Noivas emagrecimento rápido, 
          Madrinhas de casamento fitness, Emagrecimento para formandas, Como emagrecer para festas, Dieta saudável para casamento, Perder peso rápido e saudável,
          Desafio de emagrecimento feminino, Programa de emagrecimento para eventos" />
      </Helmet>
      <MetaPixel />
      <UTMify />

      <ModalDownloadPlanner {...modalDownloadProps} />
      <ModalPurchaseLead {...modalPurchaseProps} />

      <main className="flex flex-col items-center justify-center h-full space-y-[100px] max-w-5xl mx-auto p-5 pb-10">
        <Header returnButton />

        <section className="flex flex-col items-center space-y-5 p-5 border rounded-md z-10">
          <h1 className="uppercase text-center text-4xl md:text-4xl text-bordo font-bold leading-[1]"> Pronta para alcançar sua melhor versão em apenas 3 semanas? </h1>
          <h2 className="text-center text-2xl md:text-2xl text-slate-600 font-medium leading-[1]">Método rápido, <strong> saudável </strong> e eficiente para perder peso e medidas. </h2>
          <VideoVSL />
        </section>

        <section className="flex flex-col items-center md:flex-row my-5 md:my-0 w-full space-y-5 md:space-x-5">
          <article className="text-bordo flex flex-col w-full space-y-4">
            <h2 className='text-4xl md:text-4xl text-center text-bordo font-bold leading-[1]'> Você precisa de apenas 3 semanas para emagrecer!</h2>
            <p className="text-lg md:text-xl text-slate-600"> Entendo como é importante se sentir bem com o nosso corpo. Todos queremos que aquela roupa perfeita realce o nosso melhor. Seja um <strong>casamento, festa, formatura ou qualquer ocasião importante,</strong> estar confortável e confiante faz toda a diferença.</p>
            <p className="text-lg md:text-xl text-slate-600"> Pensando nisso, criamos o Programa 3 Semanas para o Vestido: uma estratégia de emagrecimento saudável de curto prazo, <strong>sem passar fome e sem dietas mirabolantes!</strong> Em apenas 3 semanas, você vai ganhar confiança e conquistar a melhor versão de si mesma!</p>
          </article>
        </section>

        <section className="flex flex-col items-center space-y-5 md:space-x-5">
          <h2 className='text-4xl text-bordo text-center font-bold leading-[1] mb-5 md:self-center'> Ainda não me conhece? </h2>
          <div className="flex flex-col md:flex-row items-center justify-center space-y-5 md:space-x-5">
            <img src={jessicaFotoBg} alt="jessica vilela foto" className="w-[250px] sm:w-[350px] rounded-md" />
            <div>
              <p className="text-lg md:text-xl text-slate-600"> Sou Jéssica Vilela, nutricionista e apaixonada por ajudar pessoas a atingirem uma vida mais saudável de forma prática e equilibrada. Sou formada em Nutrição, com pós-graduação em Nutrição Esportiva, venho construindo uma carreira dedicada a promover saúde sem extremos, com <strong> mais de 1.400 atendimentos realizados.</strong></p>
              <br />
              <p className="text-lg md:text-xl text-slate-600">Como empreendedora, influenciadora, mãe e esposa, acredito que uma alimentação saudável é aquela que se adapta à vida real e traz mais bem-estar e alegria ao nosso dia a dia. Sou apaixonada pela família, pelos amigos, pelo esporte e pelo poder transformador de uma nutrição consciente e equilibrada.</p>
              <br />
              <p className="text-lg md:text-xl text-slate-600">Agradeço por estar aqui e por confiar no meu trabalho! Vamos juntas nessa jornada em busca da melhor versão de você mesma!</p>
            </div>
          </div>
        </section>

        <section className="flex flex-col items-center md:flex-col my-5 md:my-0 w-full space-y-5 md:space-x-5">
          <h2 className='text-4xl md:text-4xl text-center text-bordo font-bold leading-[1]'> Como vai funcionar?</h2>
          <div className="flex flex-col items-center md:flex-row my-5 md:my-0 w-full space-y-5 md:space-x-5">
            <article className="text-bordo flex flex-col w-full space-y-4">
              <p className="text-lg md:text-xl text-slate-600">O programa <strong>3 Semanas para o Vestido</strong> é um desafio cuidadosamente elaborado para ajudá-la a entrar no vestido e se sentir confiante e radiante. </p>

              <p className="text-lg md:text-xl text-slate-600"> Com três módulos únicos, cada semana oferece um plano com: <strong>Lista de compras, dieta, receitas e o treino </strong>
                específico para resultados rápidos e saudáveis. <strong>Nada de dietas mirabolantes ou passar fome</strong> - apenas um guia prático que funciona!</p>

              <p className="text-lg md:text-xl text-slate-600">Aqui, não prometemos milagres, mas com o seu foco e determinação, <strong>vamos transformar seu corpo! </strong></p>

            </article>
            <article className="flex flex-col justify-around w-full space-y-5">
              <div className="flex flex-col w-auto h-auto p-5 bg-verde opacity-90 rounded-xl shadow-sm  duration-300">
                <h3 className="text-white text-2xl font-medium"> 1ª Semana </h3>
                <p className="text-white text-lg leading-none"> Purificando o corpo para um novo começo </p>
              </div>
              <div className="flex flex-col w-auto h-auto p-5 bg-bordo opacity-90 rounded-xl shadow-sm  duration-300">
                <h3 className="text-white text-2xl font-medium"> 2ª Semana </h3>
                <p className="text-white text-lg leading-none"> Reduzindo carboidratos para acelerar resultados </p>
              </div>
              <div className="flex flex-col w-auto h-auto p-5 bg-laranja opacity-90 rounded-xl shadow-sm  duration-300">
                <h3 className="text-white text-2xl font-medium"> 3ª Semana </h3>
                <p className="text-white text-lg leading-none"> Queimando gordura com força máxima </p>
              </div>
            </article>
          </div>
        </section>

        <section className="flex flex-col w-full">
          <h2 className='text-4xl text-center text-bordo font-bold leading-[1] mb-5 md:self-center'> O que você receberá? </h2>
          <div className="mt-6 grid grid-cols-2 gap-2 md:grid-cols-3 lg:mt-8 z-10">
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                <p className="text-slate-600 text-lg uppercase font-medium"> Lista de compras para cada semana </p>
              </span>
            </div>
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                <p className="text-slate-600 text-md uppercase font-medium"> Dieta para cada semana </p>
              </span>
            </div>
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                <p className="text-slate-600 text-md uppercase font-medium"> Ebook de receitas para cada semana </p>
              </span>
            </div>
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                <p className="text-slate-600 text-md uppercase font-medium"> Quadro para voce acompanhar seu progresso e medir os resultados </p>
              </span>
            </div>
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                <p className="text-slate-600 text-md uppercase font-medium"> Lista de substituições dos alimentos, caso tenha alguma preferência</p>
              </span>
            </div>
            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                <p className="text-slate-600 text-md uppercase font-medium"> Desafio da hidratação </p>
              </span>
            </div>

            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                <p className="text-slate-600 text-md uppercase font-medium"> Estratégias mentais para o sucesso </p>
              </span>
            </div>

            <div className="col-span-1 flex items-center bg-gray-100 p-4 md:p-8">
              <span className="flex items-center space-x-3">
                <p className="text-slate-600 text-md uppercase font-medium"> 4 Ebooks extras *</p>
              </span>
            </div>

          </div>
          <h6 className="text-start text-md text-slate-600 font-medium mt-3"> * opcional </h6>
        </section>

        <section className="flex flex-col w-full items-center space-y-2 md:space-x-2">
          <h2 className='text-4xl text-center text-bordo font-bold leading-[1]'> E MAIS um módulo de exercícios físicos * </h2>
          <h3 className="text-center text-2xl md:text-2xl text-slate-600 font-medium leading-[1]"> elaborado pelo nosso Personal Trainer, para fazer em casa, na rua ou na academia. </h3>
          <div className="flex flex-col md:flex-row gap-1">
            <img src={training1} alt="mulher correndo" className="w-[350px] h-[350px] md:h-auto rounded-lg object-cover z-10" />
            <img src={training2} alt="mulher na academia" className="w-[350px] h-[350px] md:h-auto rounded-lg object-cover z-10" />
          </div>
          <h6 className="text-start text-md text-slate-600 font-medium"> * opcional </h6>
        </section>

        <section className="flex flex-col items-center md:flex-row my-5 md:my-0 w-full space-y-5 md:space-x-5">
          <article className="text-bordo flex flex-col w-full space-y-4">
            <h2 className='text-2xl text-center text-slate-600 font-bold leading-[1]'> Quer saber mais como funciona? Baixe agora mesmo o nosso Planner gratuitamente!</h2>
          </article>
          <Button className="px-5 py-5 w-80 text-center text-md rounded-md text-white bg-[#48BFDB] duration-300 shadow-lg text-xl mx-auto h-[68px]" onPress={modalDownloadProps.onOpen}> Baixar Planner Grátis</Button>
        </section>

        <section className="flex flex-col w-full items-center space-y-2 md:space-x-2">
          <h2 className='text-4xl text-center text-bordo font-bold leading-[1] mb-5'> O que minhas pacientes dizem? </h2>
          <div className="flex flex-wrap gap-3 justify-center">
            <img src={print2} alt="depoimento 2" className="w-[300px] rounded-md" />
            <img src={print3} alt="depoimento 3" className="w-[300px] rounded-md" />
            <img src={print4} alt="depoimento 4" className="w-[300px] rounded-md" />
            <img src={print5} alt="depoimento 5" className="w-[300px] rounded-md" />
            <img src={print6} alt="depoimento 6" className="w-[300px] rounded-md" />
            <img src={print8} alt="depoimento 8" className="w-[300px] rounded-md" />
            <img src={print9} alt="depoimento 9" className="w-[300px] rounded-md" />
          </div>
        </section>

        {/* PRICE 1 */}
        <section className="flex flex-col items-center space-y-5 md:space-x-5 z-10">
          <h2 className='text-4xl md:text-4xl text-bordo font-bold leading-[1] mb-5 md:self-center text-center'> Você está a um passo de mudar sua rotina e se sentir maravilhosa! Não deixe para depois: comece sua transformação agora! </h2>
          <img id="price-1" src={elegantDress13} alt="3 Semanas para o Vestido logo" className="w-[300px] sm:w-[300px] rounded-md" />
          <span className='text-2xl font-semibold text-red-600 line-through'> de R$ 197,00 </span>
          <span className='text-4xl font-bold text-3spv-verde !mt-0'> <span className='text-2xl'>por 12x de </span><span className='text-6xl font-bold text-3spv-verde'>R$9,70</span> </span>
          {/* <span className='text-xl font-semibold text-3spv-verde !mt-0'> ou R$ 97,00 à vista</span> */}
          <Button className="px-5 py-5 w-80 text-center text-md rounded-md text-white bg-3spv-verde-escuro hover:bg-3spv-verde duration-300 shadow-lg text-xl h-[68px]" onPress={modalPurchaseProps.onOpen}> Quero começar agora! </Button>
        </section>

        <section className="flex flex-col items-center space-y-5 md:space-x-5 w-full z-10">
          <h2 className='text-4xl text-bordo font-bold leading-[1] mb-5 md:self-center'> Perguntas frequentes </h2>
          <Accordion variant="bordered">
            <AccordionItem key="1" aria-label="Accordion 1" title="O que é o programa 3 Semanas para o Vestido?">
              O programa é um desafio intensivo de três semanas, criado especialmente para quem deseja perder medidas de forma saudável e rápida. Ele combina alimentação equilibrada, hidratação e exercícios diários para promover a transformação sem extremos.
            </AccordionItem>
            <AccordionItem key="2" aria-label="Accordion 2" title="Como vou receber o material?">
              Assim que a compra for concluída, você terá acesso área do aluno da plataforma, onde vão estar disponíveis os ebooks em seus respectivos módulos. Todo o conteúdo está disponível em formato digital para que você possa acessá-lo onde e quando quiser.
            </AccordionItem>
            <AccordionItem key="3" aria-label="Accordion 3" title="Preciso seguir o programa exatamente como está para ver resultados?">
              Sim! Para alcançar os melhores resultados, é importante seguir o plano de alimentação e exercícios conforme as orientações. Cada etapa foi pensada para otimizar a perda de medidas e garantir um progresso saudável ao longo das três semanas.
            </AccordionItem>
            <AccordionItem key="4" aria-label="Accordion 4" title=" E se eu tiver restrições alimentares?">
              O programa inclui substituições e adaptações para tornar a dieta mais flexível e acessível para diferentes necessidades. Assim, você pode ajustar o plano para atender a restrições específicas sem comprometer os resultados.
            </AccordionItem>
            <AccordionItem key="5" aria-label="Accordion 5" title="Quanto tempo preciso dedicar aos exercícios?">
              Os exercícios diários são rápidos e eficazes, pensados para quem tem uma rotina corrida. Eles levam em média 30 a 60 minutos por dia e podem ser feitos em casa, sem a necessidade de equipamentos complexos, ou na academia.
            </AccordionItem>
            <AccordionItem key="6" aria-label="Accordion 6" title="Preciso de experiência com dietas ou exercícios para participar?">
              Não! O programa foi desenhado para todos os níveis, do iniciante ao avançado. Ele traz orientações detalhadas para que qualquer pessoa possa seguir o plano de maneira prática e segura.
            </AccordionItem>
            <AccordionItem key="7" aria-label="Accordion 7" title="O programa exige alimentos caros ou difíceis de encontrar?">
              Não! Os alimentos sugeridos no programa são acessíveis e fáceis de encontrar em qualquer supermercado. Também oferecemos opções de substituição para tornar o plano prático e adaptável à sua rotina.
            </AccordionItem>
            <AccordionItem key="8" aria-label="Accordion 8" title="Em quanto tempo posso esperar ver os primeiros resultados?">
              Embora cada corpo responda de forma diferente, muitos participantes relatam mudanças visíveis e perda de medidas já na primeira semana. Com dedicação ao plano, você verá resultados progressivos ao longo das três semanas.
            </AccordionItem>
            <AccordionItem key="9" aria-label="Accordion 9" title="Os exercícios podem ser feitos por pessoas de qualquer idade?">
              Os exercícios são acessíveis para a maioria das pessoas, mas é sempre recomendável consultar um profissional de saúde, especialmente se você tiver alguma restrição física. Todos os exercícios são adaptáveis para respeitar o seu ritmo e condição física.
            </AccordionItem>
            <AccordionItem key="10" aria-label="Accordion 10" title="Quanto peso posso esperar perder em 3 semanas?">
              Os resultados variam de pessoa para pessoa, é muito difícil dar um número. Lembre-se, o foco é perder medidas de forma saudável, então os resultados podem ir além da balança.
            </AccordionItem>
            <AccordionItem key="11" aria-label="Accordion 11" title="E se eu não gostar do programa?">
              Estamos comprometidos com a sua satisfação. Se, por algum motivo, o programa não atender às suas expectativas, oferecemos uma política de reembolso. Entre em contato conosco nos primeiros dias e garantiremos o suporte necessário.
            </AccordionItem>
          </Accordion>
        </section>

        <section className="flex flex-col items-center space-y-5 md:space-x-5 z-10">
          <img src={garantia7} alt="selo garantia" className="w-[300px] sm:w-[300px] rounded-md" />
          <span className='text-2xl font-semibold text-center text-slate-600'> SE POR QUALQUER MOTIVO VOCÊ ACHAR QUE O PROGRAMA NÃO É PARA VOCÊ, OU SE VOCÊ ACHAR QUE NÃO VAI CONSEGUIR SEGUIR AS DIETAS, BASTA SOLICITAR O REEMBOLSO.  </span>
        </section>

        {/* PRICE 2 */}
        <section id="price-2" className="flex flex-col items-center space-y-5 md:space-x-5 z-10">
          <h2 className='text-4xl md:text-4xl text-bordo font-bold leading-[1] mb-5 md:self-center text-center'> Este é o seu momento! 3 semanas podem fazer toda a diferença! </h2>
          <img src={elegantDress10} alt="3 Semanas para o Vestido logo" className="w-[300px] sm:w-[300px] rounded-md" />
          <span className='text-2xl font-semibold text-red-600 line-through'> de R$ 197,00 </span>
          <span className='text-4xl font-bold text-3spv-verde !mt-0'> <span className='text-2xl'>por 12x de </span><span className='text-6xl font-bold text-3spv-verde'>R$9,70</span> </span>
          {/* <span className='text-xl font-semibold text-3spv-verde !mt-0'> ou R$ 97,00 à vista</span> */}
          <Button className="px-5 py-5 w-80 text-center text-md rounded-md text-white bg-3spv-verde-escuro hover:bg-3spv-verde duration-300 shadow-lg text-xl h-[80px]" onPress={modalPurchaseProps.onOpen}> Comece sua transformação agora! </Button>
        </section>
      </main>
    </>
  );
}

export default TresPV;